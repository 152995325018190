const model = {
  modelName: 'shop_notice_class',
  label: '購物須知類別',
  fields: {
    id: {
      type: 'text',
      label: 'ID',
      readonly: true,
    },
    name: {
      type: 'text',
      label: '類別名稱',
    },
  },
};

export default model;
